















































































































































































































































































































































































.addlectuer {
  .addlist {
    h3 {
      display: flex;
      border-left: 5px solid rgb(92, 107, 232);
      padding: 0;
      span {
        margin-left: 20px;
      }
      margin-bottom: 30px;
    }
    h3::before {
      content: "";
      border: 0;
    }
  }
}

.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.form-box {
  padding: 10px 20px;
  .form {
    width: 70%;
    padding: 20px;
  }
  .form-bg {
    background-color: rgb(247, 248, 253);
  }
}
.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: space-around;
  width: 70%;
  button {
    padding: 12px 30px;
  }
}
.table-switch {
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}
